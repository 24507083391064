<template>
  <div class="md-layout text-center">
    <div class="md-layout-item md-xlarge-size-40 md-large-size-40 md-medium-size-50 md-small-size-70 md-xsmall-size-100">
      <login-form @on-submit="onLoginSubmit"></login-form>
    </div>
  </div>
</template>
<script>

import LoginForm from '../Forms/ValidationForms/LoginForm.vue'
import swal from 'sweetalert2'

export default {
  components: {
    LoginForm
  },
  methods: {
    onLoginSubmit (model, isValid) {
      if (isValid) {
        var self = this
        var formData = new FormData();
        formData.append('email', model.email);
        formData.append('password', model.password);
        var xhr = new XMLHttpRequest()
        xhr.open('POST', this.$root.apiUrl + 'v1/login')
        xhr.onload = function () {
          if (xhr.status === 401) {
            self.showSweetAlert401()
            self.$router.push({name: 'Login'})
          } else if (xhr.status === 200) {
            var response = JSON.parse(xhr.responseText)
            localStorage.setItem('token', response.token)
            localStorage.setItem('user', response.user)
            localStorage.setItem('admin_id', response.admin_id)
            localStorage.setItem('avatar', response.avatar)
            self.$router.push({name: 'Dashboard'})
          }
        }
        xhr.send(formData)
      }
    },
    showSweetAlert401 () {
      swal({
        title: 'Unauthorized!',
        text: 'Email address or password is invalid.',
        type: 'warning',
        showConfirmButton: false,
        showCancelButton: true,
        cancelButtonText: 'Try again',
        cancelButtonClass: 'md-button md-success',
        buttonsStyling: false
      })
    }
  }
}

</script>

<style>
</style>
